import * as React from 'react'
import { useState, useCallback } from 'react'
import isNotEmpty from './validators/isNotEmpty'
import inputValidator from './validators/inputValidator'
import PropTypes from 'prop-types'
import { FormContext } from './FormWrapper'

export const USStatesOptions = [
  { value: '', label: 'State' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
]

const InputSelect = ({
  className,
  options,
  onChange,
  form,
  name,
  empty,
  onFocus,
  onBlur,
  id,
  label = '',
  required = false,
  validator = isNotEmpty,
}) => {
  const forceValidation = React.useContext(FormContext)
  const [focus, setFocus] = useState(false)
  const [pristine, setPristine] = useState(true)
  const [validation, setValidation] = useState({ isValid: true, message: '' })

  const error = !validation.isValid && !pristine

  const onChangeHandler = useCallback(
    e => {
      setPristine(false)
      const validation = inputValidator(e.target.value, validator, required, true, false)
      setValidation(validation)
      e.target.valid = validation.isValid
      if (onChange) onChange(e)
    },
    [onChange, setPristine, setValidation, required, validator]
  )

  const onFocusHandler = useCallback(
    e => {
      setFocus(true)
      if (onFocus) onFocus(e)
    },
    [setFocus]
  )
  const onBlurHandler = useCallback(
    e => {
      setFocus(false)
      setPristine(false)
      setValidation(inputValidator(e.target.value, validator, required, false, false))
      if (onBlur) onBlur(e)
    },
    [setFocus, setPristine, required, validator, setValidation]
  )
  React.useEffect(() => {
    if (!forceValidation) {
      setPristine(true)
      return
    }
    setPristine(false)
    const validation = inputValidator(form[name], validator, required, true, false)
    setValidation(validation)
  }, [forceValidation, form[name], validator, required, setValidation, setPristine])

  return (
    <div className={'relative mt-4 ' + className}>
      <div
        className={
          'pointer-events-none absolute top-0 left-0 h-4 transform px-2 text-gray-500 duration-100 z-[1] ease-out ' +
          (focus || (form && form[name]) ? '-translate-y-2 text-xs ' : 'translate-y-3')
        }
      >
        <label
          className={'bg-white px-2' + (error ? ' text-red-500' : '')}
          htmlFor={(id || '') + name}
        >
          {' '}
          <nobr>
            {label}: {required ? '*' : ''}
          </nobr>
        </label>
      </div>
      <div className="flex flex-row relative group space-y-4 ">
        <select
          name={name}
          required={required}
          id={id}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          className="relative h-12 w-full bg-transparent px-2 border"
          value={form[name]}
        >
          {empty && <option value="">{empty}</option>}
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>

        {/* {error ? (
          <>
            <div className="relative -top-[.6rem] left-5 w-3 h-3 z-50 border-t-2 border-l-2 bg-white border-red-500 rotate-45"></div>
            <div className="bg-white mr-14 w-full border-2 border-red-500 z-40 py-1 px-2 shadow-md rounded-lg absolute text-sm text-red-700 -translate-y-4 -translate-x-2">
              {formatErrorMessage(validation, label)}
            </div>
          </>
        ) : null} */}
      </div>
    </div>
  )
}

InputSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  form: PropTypes.object,
  name: PropTypes.string,
  empty: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  validator: PropTypes.func,
  label: PropTypes.string,
}

export default InputSelect
