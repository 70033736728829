import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { IconCirclePlus } from '../Icons'
import { changeStatus, CHECKOUT_STATUS_FAILED } from '../../state/cart'
import {
  updateCheckoutField,
  copyShippingToBilling,
  resetBilling,
  resetShipping,
} from '../../state/checkout'
import InputField from '../form/InputField'
import FormWrapper from '../form/FormWrapper'
import isEmail from '../form/validators/isEmail'
import isPhone from '../form/validators/isPhone'
import InputSelect, { USStatesOptions } from '../form/InputSelect'
import { TrackEvent } from '../../helpers/TrackingHelper'

const CheckoutDetails = ({ setStep }) => {
  const [billingFields, setBillingFields] = React.useState(false)
  const form = React.useRef()
  const checkout = useSelector(state => state.checkout)
  const cart = useSelector(state => state.checkout)
  const cartStatus = useSelector(state => state.cart.status)
  const cartStatusHighlight = useSelector(state => state.cart.status_highlight)
  const cartStatusMessage = useSelector(state => state.cart.status_message)
  const dispatch = useDispatch()
  const updateShipping = React.useCallback(
    e => {
      // updateForm(e)
      dispatch(updateCheckoutField({ name: e.target.name, value: e.target.value }))
    },
    [dispatch]
  )
  const clearForm = React.useCallback(
    e => {
      e.preventDefault()
      dispatch(resetShipping())
    },
    [dispatch]
  )
  const clearForm2 = React.useCallback(
    e => {
      e.preventDefault()
      dispatch(resetBilling())
    },
    [dispatch]
  )
  const copyShipping = React.useCallback(
    () => {
      if (!billingFields) {
        dispatch(copyShippingToBilling())
      }
    },
    [dispatch, billingFields]
  )
  const showBillingDetailsOrCopyShipping = React.useCallback(
    e => {
      e.preventDefault()
      setBillingFields(!billingFields)
      if (billingFields) {
        dispatch(copyShippingToBilling())
      } else {
        dispatch(resetBilling())
      }
    },
    [billingFields, setBillingFields, dispatch]
  )

  const onSubmit = React.useCallback(() => {
    if (cartStatus === CHECKOUT_STATUS_FAILED && cartStatusHighlight === 'form') {
      dispatch(
        changeStatus({
          status: CHECKOUT_STATUS_FAILED,
          message: cartStatusMessage,
          show: true,
          highlight: 'form',
        })
      )
      return
    }
    TrackEvent('Checkout Started', {
      checkout,
      cart,
    })
    setStep(2)
  }, [setStep, cartStatus, cartStatusMessage, billingFields, cart, checkout])
  const onValidateionError = React.useCallback(message => {
    console.log('Error')
    dispatch(
      changeStatus({
        status: CHECKOUT_STATUS_FAILED,
        message: message,
        show: true,
        highlight: 'form',
      })
    )
  }, [])

  return (
    <div className="w-full max-w-[580px]">
      <FormWrapper
        name={'Checkout'}
        action={onSubmit}
        onValidationError={onValidateionError}
        form={checkout}
        ref={form}
        saveOnSubmit={false}
        minimumTimeout={0}
      >
        <section>
          <div className="flex flex-col justify-center">
            <h1 className="text-2xl font-bold uppercase text-gray-500">SHIPPING DETAILS</h1>
            <div className="grid w-full grid-cols-4 ">
              <div className="col-span-4 flex justify-center ">
                <div className="grid w-full grid-cols-4 gap-x-5 ">
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_name'}
                    label={'First Name'}
                    form={checkout}
                    onChange={updateShipping}
                    required={true}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_last_name'}
                    label={'Last Name'}
                    form={checkout}
                    onChange={updateShipping}
                    required={true}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_address'}
                    label={'Address'}
                    form={checkout}
                    onChange={updateShipping}
                    required={true}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_address2'}
                    label={'Apt/Suite'}
                    form={checkout}
                    required={false}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_city'}
                    label={'City'}
                    form={checkout}
                    onChange={updateShipping}
                    required={true}
                  />
                  {/* <InputField
                    className="col-span-2 lg:col-span-1"
                    name={'shipping_state'}
                    label={'State'}
                    form={checkout}
                    onChange={updateShipping}
                    required={true}
                  />{' '} */}
                  <InputSelect
                    className="col-span-2 lg:col-span-1"
                    name={'shipping_state'}
                    label={'State'}
                    form={checkout}
                    options={USStatesOptions}
                    onChange={updateShipping}
                    required={true}
                  />
                  <InputField
                    className="col-span-2 lg:col-span-1"
                    name={'shipping_zip'}
                    type={'number'}
                    label={'Zip'}
                    form={checkout}
                    onChange={updateShipping}
                    required={true}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'phone'}
                    label={'Telephone'}
                    type="tel"
                    form={checkout}
                    details="A telephone number is required to contact you in case of a problem with your order."
                    validator={isPhone}
                    onChange={updateShipping}
                    required={true}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'email'}
                    label={'E-Mail'}
                    type="email"
                    form={checkout}
                    details="An email address is required to send you a confirmation and shipping information of your order."
                    validator={isEmail}
                    onChange={updateShipping}
                    required={true}
                  />
                  <button
                    type="reset"
                    className="text-sm col-span-4 text-left text-gray-400 hover:text-red-700 lg:col-span-2 flex items-center group my-4"
                    onClick={clearForm}
                  >
                    <IconCirclePlus className="w-5 h-5 rotate-45 mr-1 stroke-1 stroke-white" />
                    Clear Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <hr className="w-full mx-auto my-5 border-b border-b-gray-300"></hr>

        <section>
          <div className="w-full">
            <h1 className=" text-2xl font-bold uppercase text-gray-500 ">BILLING DETAILS</h1>
            <button
              onClick={showBillingDetailsOrCopyShipping}
              className={
                (billingFields ? 'text-gray-400 ' : 'text-red-600 ') +
                ' opacity-100 duration-300 pl-8 relative group text-md col-span-2 pb-3 text-left  hover:text-red-600 lg:col-span-1 xl:col-span-1 flex flex-row items-center'
              }
            >
              <div className={' h-6 w-6  absolute top-0 left-0 border-2 border-red-600 z-10 '}>
                <p
                  className={
                    (billingFields ? 'text-red-600 rotate-180 ' : 'text-white ') +
                    ' z-20 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] font-bold duration-300 ease-in-out'
                  }
                >
                  {billingFields ? <b>&#10005;</b> : <i>&#10003;</i>}
                </p>
                <div
                  className={
                    (billingFields ? 'h-0 ' : ' h-5 ') +
                    ' w-5 bg-red-600 absolute top-0 left-0 z-0 duration-200'
                  }
                ></div>
              </div>
              Same as Shipping Details
            </button>
            <div
              className={
                (billingFields
                  ? ' h-[37em] lg:h-[20em] opacity-100 lg:mb-3  '
                  : ' h-[0em] opacity-0 mb-5  ') + ' duration-300 overflow-y-hidden'
              }
            >
              {billingFields && (
                <div className="flex justify-center">
                  <div className="grid w-full grid-cols-4 ">
                    <div className="col-span-4 flex justify-center ">
                      <div className="grid w-full grid-cols-4 gap-x-5">
                        <InputField
                          className="col-span-4 lg:col-span-2"
                          name={'billing_name'}
                          label={'First Name'}
                          form={checkout}
                          onChange={updateShipping}
                          required={true}
                        />
                        <InputField
                          className="col-span-4 lg:col-span-2"
                          name={'billing_last_name'}
                          label={'Last Name'}
                          form={checkout}
                          onChange={updateShipping}
                          required={true}
                        />
                        <InputField
                          className="col-span-4 lg:col-span-2"
                          name={'billing_address'}
                          label={'Address'}
                          form={checkout}
                          onChange={updateShipping}
                          required={true}
                        />
                        <InputField
                          className="col-span-4 lg:col-span-2"
                          name={'billing_address2'}
                          label={'Apt/Suite'}
                          form={checkout}
                          required={false}
                          onChange={updateShipping}
                        />
                        <InputField
                          className="col-span-4 lg:col-span-2"
                          name={'billing_city'}
                          label={'City'}
                          form={checkout}
                          onChange={updateShipping}
                          required={true}
                        />
                        <InputSelect
                          className="col-span-2 lg:col-span-1"
                          name={'billing_state'}
                          label={'State'}
                          form={checkout}
                          options={USStatesOptions}
                          onChange={updateShipping}
                          required={true}
                        />{' '}
                        <InputField
                          className="col-span-2 lg:col-span-1"
                          name={'billing_zip'}
                          label={'Zip'}
                          type={'number'}
                          form={checkout}
                          onChange={updateShipping}
                          required={true}
                        />
                        <InputField
                          className="col-span-4 lg:col-span-2"
                          name={'billing_phone'}
                          label={'Telephone'}
                          type="tel"
                          form={checkout}
                          validator={isPhone}
                          onChange={updateShipping}
                          required={true}
                        />
                        <InputField
                          className="col-span-4 lg:col-span-2"
                          name={'billing_email'}
                          label={'E-Mail'}
                          form={checkout}
                          type="email"
                          validator={isEmail}
                          onChange={updateShipping}
                          required={true}
                        />
                        <button
                          type="reset"
                          className="text-sm col-span-4 text-left text-gray-400 hover:text-red-700 lg:col-span-2 flex items-center group my-4"
                          onClick={clearForm2}
                        >
                          <IconCirclePlus className="w-5 h-5 rotate-45 mr-1 stroke-1 stroke-white" />
                          Clear Form
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <button
          onClick={copyShipping}
          className={
            (billingFields ? 'mb-0 ' : ' ') +
            ' col-span-2 w-full md:w-48 border-2 border-red-700 bg-white py-2 font-bold text-red-700 hover:border-red-700 hover:bg-red-700 hover:text-white lg:col-span-1 xl:col-span-1 duration-200 '
          }
        >
          NEXT
        </button>
      </FormWrapper>
    </div>
  )
}
CheckoutDetails.propTypes = {
  setStep: PropTypes.func,
}

export default CheckoutDetails
